import React from 'react';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import classes from './mob-property-card-star-rating-material.module.scss';

function MobPropertyCardStarRatingMaterial(props) {
  const { rating, totalStars = 5, starClass, emptyStarClass } = props;

  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 !== 0;
  const emptyStars = totalStars - fullStars - (hasHalfStar ? 1 : 0);

  return (
    <div className={classes.rootClass} style={{ display: 'flex', color: '#fff' }}>
      {[...Array(fullStars)].map((_, index) => (
        <div key={index} className={starClass}>
          <StarIcon />
        </div>
      ))}
      {hasHalfStar && (
        <div className={starClass}>
          <StarIcon style={{ clipPath: 'inset(0 50% 0 0)' }} />
          <StarBorderIcon
            style={{
              clipPath: 'inset(0 0 0 50%)',
              position: 'absolute',
              marginLeft: '-16px',
            }}
          />
        </div>
      )}
      {[...Array(emptyStars)].map((_, index) => (
        <div key={index} className={emptyStarClass}>
          <StarBorderIcon />
        </div>
      ))}
    </div>
  );
}

export default MobPropertyCardStarRatingMaterial;
