/* ########## COMPONENT IMPORTS ########## */
import useAccessToken from '@/components/packages/use-access-token';
import SessionStorage from '@/components/Desktop/Common/Wishlist/SessionStorage';
import useMaterialMediaQuery from '@/components/packages/use-material-media-query';
import CommonCardContainer from '@/components/packages/common-card-container';
// import PropertyCardStarRatingMaterial from '@/components/packages/property-card-star-rating-material';
import MobPropertyCardStarRatingMaterial from '../mob-property-card-star-rating-material';
import HeaderButton from '@/components/packages/header-button';
import ImagesSlider from '@/components/packages/images-slider';

/* ########## MATERIAL UI IMPORTS ########## */
import { Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
// import { makeStyles } from '@material-ui/core/styles';

import React, { useState, useEffect } from 'react';
import ClassNames from 'classnames';
import _ from 'lodash';
import {
  getPropertyDetailsURL,
  // getPropertyDetailsURL,
  getPropertyDetailsURLForRentResale,
} from '../../util';

import Variables from 'styles/variables.module.scss';
import Link from 'next/link';
import classes from './propertyCard.module.scss';

export default function MenuItemViewProject(props) {
  const {
    cardIndex,
    rootStyle,
    images,
    p_we_recommend_count,
    street_url,
    video_url,
    p_project_name,
    developer_name,
    price,
    bhk_types,
    sqft,
    location,
    locationwithoutCityName,
    livability_index,
    investment_index,
    scheduleTourClick,
    property_id,
    property_name,
    buildingName,
    propertyDetailsGallery,
    projectName,
    wishlists,
    toggleWishlist,
    module_type,
    cityName,
    offer,
    dealOffer,
    isviewlist,
    priceToshow,
    minPrice,
    maxPrice,
    cachedProject,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    setDeleteCachedPropertyTrigger,
    deletecachedpropertytrigger,
    furnishingStatus,
    maxCarpetArea,
    minCarpetArea,
    isResale,
  } = props;
  // const classes = useStyles();
  const [wishlist_flag, setWishlistFlag] = useState(wishlists);
  const [access_token] = useAccessToken();
  const isRent = module_type == 'rent';

  useEffect(() => {
    if (access_token && access_token != '') {
      setWishlistFlag(wishlists);
    } else {
      setWishlistFlag(0);
      cachedProject &&
        cachedProject.map(data => {
          const { project } = data;
          project &&
            project.map(dataCached => {
              const { propertyId } = dataCached;

              if (propertyId == property_id) {
                setWishlistFlag(1);
              }
            });
        });
    }
  }, [
    wishlists,
    cachedProject,
    access_token,
    wishlistpropertydeletedtrigger,
    addwishlisttrigger,
    property_id,
  ]);

  const [small, medium, large] = useMaterialMediaQuery();
  const setWishlistFlagFunc = _.debounce(propertyid => {
    toggleWishlist(propertyid);
    setWishlistFlag(!wishlist_flag);
  });
  const saveDatainSession = (
    investment_index_save,
    cityNameSave,
    property_id_save,
    projectNameSave,
    buildingNameSave,
    bhk_types_save,
    furnishingStatusSave,
    developer_name_save,
    livability_index_save,
    location_save,
    module_type_save,
    images_save,
    priceToshowSave,
    street_url_save,
    video_url_save,
    p_we_recommend_count_save,
    sqft_save,
    minPriceSave,
    maxPriceSave,
    minCarpetAreaSave
  ) => {
    SessionStorage({
      investment_index_save,
      cityNameSave,
      property_id_save,
      projectNameSave,
      buildingNameSave,
      bhk_types_save,
      furnishStatus: furnishingStatusSave,
      developer_name_save,
      livability_index_save,
      location_save,
      module_type_save,
      images_save,
      priceToshowSave,
      street_url_save,
      video_url_save,
      p_we_recommend_count_save,
      sqft_save,
      minPriceSave,
      maxPriceSave,
      minCarpetAreaSave,
      isResale,
      maxCarpetArea,
    });
    setWishlistFlag(!wishlist_flag);
    setDeleteCachedPropertyTrigger(!deletecachedpropertytrigger);
  };

  let url = '';
  // @TO-DO the name of variable 'module' has  chenged by moduleType"
  let moduleType = module_type;
  let isWishlistRentReasale = true;
  let isResaleProp = isResale;

  if (module_type == 'resale') {
    isResaleProp = true;
    moduleType = 'buy';
  }
  if (isResaleProp || isRent) {
    url = getPropertyDetailsURLForRentResale(
      moduleType,
      cityName,
      bhk_types,
      maxCarpetArea,
      buildingName || property_name,
      property_id,
      locationwithoutCityName,
      isResaleProp,
      isWishlistRentReasale,
      sqft,
      isResale
      // null
      // isLogin,
    );
  } else {
    url = getPropertyDetailsURL({
      module_type: moduleType,
      city_name: cityName,
      property_name: property_name,
      property_id: property_id,
      location: locationwithoutCityName,
    });
  }

  let propertyURL = getPropertyDetailsURL({
    module_type: module_type,
    city_name: cityName,
    property_name: property_name,
    property_id: property_id,
    location: locationwithoutCityName,
    bhkTypes: isRent ? bhk_types : '',
    isResale: isResale,
  });

  function handleScheduleTourClick(propertyId, projectNameTour, e) {
    e.preventDefault();
    e.stopPropagation();
    scheduleTourClick(propertyId, projectNameTour);
  }

  return (
    <CommonCardContainer url={url} isOpenNewTab={true}>
      <div
        style={rootStyle}
        className={ClassNames([classes.root, 'property-card'])}>
        {images && (
          <div className={classes.cardTop}>
            <ImagesSlider
              isFirstSearchCard={cardIndex === 0}
              images={images}
              projectName={projectName}
              width={338}
              height={174}
            />
            <Grid
              className={classes.cardTopIcons}
              container
              justifyContent="space-between">
              <Grid item xs={6}>
                {module_type !== 'rent' && (
                  <MobPropertyCardStarRatingMaterial
                    rating={p_we_recommend_count}
                    precision={0.5}
                    root_class={classes.iconFilled}
                    emptyIcon={classes.iconBorder}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent="flex-end" spacing={1}>
                  {street_url && street_url != '' && (
                    <Grid
                      className="cardNotClicked"
                      style={{
                        cursor: 'pointer',
                      }}
                      item
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        propertyDetailsGallery(
                          'STREET_VIEW',
                          street_url,
                          video_url,
                          property_id
                        );
                      }}>
                      <img
                        src="/static/images/home-icons.svg"
                        alt="Street View"
                        style={{
                          objectPosition: '-346px 0px',
                          objectFit: 'cover',
                          width: '26px',
                          height: '38px',
                        }}
                      />
                    </Grid>
                  )}
                  {video_url && video_url != '' && (
                    <Grid
                      className="cardNotClicked"
                      style={{
                        cursor: 'pointer',
                      }}
                      item
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        propertyDetailsGallery(
                          'VIDEO_VIEW',
                          street_url,
                          video_url,
                          property_id
                        );
                      }}>
                      <img
                        src="/static/images/home-icons.svg"
                        alt="Video"
                        style={{
                          objectPosition: '-312px 0px',
                          objectFit: 'cover',
                          width: '26px',
                          height: '38px',
                        }}
                      />
                    </Grid>
                  )}
                  {isviewlist == 'view-shortlisted' ? null : (
                    <Grid
                      className="cardNotClicked"
                      item
                      style={{
                        cursor: 'pointer',
                        marginBottom: '20px',
                      }}
                      onClick={
                        access_token
                          ? e => {
                              e.preventDefault();
                              e.stopPropagation();
                              setWishlistFlagFunc(property_id);
                            }
                          : e => {
                              e.preventDefault();
                              e.stopPropagation();
                              saveDatainSession(
                                investment_index,
                                cityName,
                                property_id,
                                projectName,
                                buildingName,
                                bhk_types,
                                furnishingStatus,
                                developer_name.props.text,
                                livability_index,
                                locationwithoutCityName,
                                module_type,
                                images,
                                priceToshow,
                                street_url,
                                video_url,
                                p_we_recommend_count,
                                sqft,
                                minPrice,
                                maxPrice,
                                minCarpetArea
                              );
                            }
                      }>
                      <img
                        className="cardNotClicked"
                        width="24px"
                        height="21px"
                        src={`/static/images/${
                          wishlist_flag
                            ? 'ionic-ios-heart.svg'
                            : 'ionic-ios-heart-empty.svg'
                        }`}
                        alt="Favourite"
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>
        )}
        <Grid
          style={{ margin: '12px 0 0 8px' }}
          container
          justifyContent="flex-start">
          <Grid item className="projectData">
            {/*eslint-disable-next-line react/jsx-no-target-blank*/}

            {p_project_name}

            {developer_name}
          </Grid>
        </Grid>
        {(offer || dealOffer) && (
          <Grid
            container
            justifyContent="flex-start"
            style={{ marginLeft: '8px', marginTop: '5px' }}>
            {offer && (
              <Grid xs={3} item style={{ marginRight: '-2px' }}>
                <Tooltip
                  title={offer}
                  classes={{
                    tooltip: classes.customTooltip1,
                    arrow: classes.arrow,
                  }}
                  arrow>
                  <div className={classes.mobDealWeek}>
                    <img
                      src="/static/images/home-icons.svg"
                      alt="metro-location"
                      style={{
                        objectPosition: '-630px 0px',
                        objectFit: 'cover',
                        width: '70px',
                        height: '30px',
                      }}
                    />
                  </div>
                </Tooltip>
              </Grid>
            )}
            {dealOffer && (
              <Grid xs={3} item>
                <Tooltip
                  title={dealOffer}
                  classes={{
                    tooltip: classes.customTooltip2,
                    arrow: classes.arrow,
                  }}
                  arrow>
                  <div className={classes.mobDealWeek}>
                    <img
                      src="/static/images/home-icons.svg"
                      alt="metro-location"
                      style={{
                        objectPosition: '-699px 0px',
                        objectFit: 'cover',
                        width: '70px',
                        height: '30px',
                      }}
                    />
                  </div>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        )}
        <Grid
          container
          justifyContent="space-between"
          style={{
            padding: '12px 0',
            marginLeft: '8px',
            minHeight: '131px',
            marginTop: '-5px',
          }}>
          <Grid xs={12} item>
            {price}
          </Grid>
          {bhk_types && module_type === 'rent' && (
            <Grid
              container
              justifyContent="flex-start"
              className={classes.bhkValues}
              spacing={1}
              style={{ marginTop: '0px' }}>
              <Grid item>
                <img
                  style={{ margin: '2px 5px 0 0' }}
                  src="/static/images/bhk.svg"
                  width="14px"
                  height="14px"
                  alt="bhk"
                />
              </Grid>
              <Grid
                item
                className={classes.bhkdata}
                // style={{
                //   color: Variables.darkColor,
                //   textOverflow: 'ellipsis',
                //   overflow: 'hidden',
                //   width: '90%',
                //   fontWeight: '500',
                // }}
              >
                {furnishingStatus ? furnishingStatus : bhk_types}
              </Grid>
            </Grid>
          )}
          {sqft && sqft.trim() && (
            <Grid
              container
              justifyContent="flex-start"
              spacing={1}
              style={{ marginTop: '0px' }}>
              <Grid item>
                <img
                  src="/static/images/home-icons.svg"
                  alt="carpet-area"
                  style={{
                    objectPosition: '-41px 2px',
                    objectFit: 'cover',
                    width: '17px',
                    height: '24px',
                    marginBottom: '-10px',
                  }}
                />
              </Grid>
              {maxCarpetArea.trim() !== '0' ? (
                <Grid item className={classes.sqftData}>
                  {parseFloat(maxCarpetArea)
                    ? module_type === 'rent'
                      ? `${maxCarpetArea} sqft`
                      : sqft.trim()
                    : 'Area On Request'}
                </Grid>
              ) : (
                <Grid
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    scheduleTourClick(
                      property_id,
                      projectName,
                      developer_name,
                      false,
                      true
                    );
                  }}
                  item
                  style={{ color: Variables.darkColor, fontWeight: '500' }}>
                  {'Area On Request'}
                </Grid>
              )}
            </Grid>
          )}
          {bhk_types && module_type !== 'rent' && (
            <Grid
              container
              justifyContent="flex-start"
              className={classes.bhkValues}
              spacing={1}
              style={{ marginTop: '0px' }}>
              <Grid item>
                <img
                  src="/static/images/home-icons.svg"
                  alt="bhk"
                  style={{
                    objectPosition: '0px 0px',
                    objectFit: 'cover',
                    width: '15px',
                    height: '25px',
                    marginBottom: '-10px',
                  }}
                />
              </Grid>
              <Grid
                item
                // style={{
                //   color: Variables.darkColor,
                //   textOverflow: 'ellipsis',
                //   overflow: 'hidden',
                //   width: '90%',
                // }}
              >
                {module_type !== 'rent' ? bhk_types : furnishingStatus}
              </Grid>
            </Grid>
          )}

          {location && location.trim() && (
            <Grid
              container
              justifyContent="flex-start"
              spacing={1}
              style={{ marginTop: '0px' }}>
              <Grid item style={{ overflow: 'hidden' }}>
                <img
                  src="/static/images/home-icons.svg"
                  alt="metro-location"
                  style={{
                    objectPosition: '-131px 9px',
                    objectFit: 'cover',
                    width: '18px',
                    height: '26px',
                    marginTop: '-9px',
                  }}
                />
              </Grid>
              <Grid item className={classes.locationData}>
                {location}
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid
          container
          spacing={2}
          justifyContent="space-between"
          className={classes.proBtnContainer}>
          <Grid item xs={6}>
            <Grid className={classes.proBtn}>
              <Link target="_blank" href={propertyURL}>
                <HeaderButton
                  data-cy={'view-details-btn'}
                  text="View Details"
                  variant="contained"
                  className="viewDetailsButton cardNotClicked"
                  style={{
                    textTransform: 'capitalize',
                    width: '100%',
                    height: '38px',
                    color: '#594F5D',
                    backgroundColor: '#F3F3FF',
                    borderRadius: '8px',
                    padding: '6px 10px',
                    fontWeight: '700',
                    fontFamily: 'Open Sans',
                    border: '1px solid #D2DEEB !important',
                  }}
                />
              </Link>
            </Grid>
          </Grid>
          {(module_type == 'buy' || module_type == 'resale') &&
            (medium || large) && (
              <Grid
                item
                xs={6}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  scheduleTourClick(property_id, projectName);
                }}>
                <Grid className={classes.proBtn}>
                  <HeaderButton
                    text="Instant Call Back"
                    variant="contained"
                    className="scheduleTourButton cardNotClicked"
                    style={{
                      textTransform: 'capitalize',
                      width: '100%',
                      height: '38px',
                      color: '#FFFFFF',
                      backgroundColor: Variables.primaryColor,
                      borderRadius: '8px',
                      padding: '0px',
                      fontWeight: '700',
                      fontFamily: 'Open Sans',
                    }}
                  />
                </Grid>
              </Grid>
            )}

          {module_type == 'rent' && (medium || large) && (
            <Grid
              item
              xs={6}
              onClick={e =>
                handleScheduleTourClick(property_id, projectName, e)
              }>
              <Grid className={ClassNames([classes.proBtn, 'cardNotClicked'])}>
                <HeaderButton
                  text="Contact Seller"
                  variant="contained"
                  className="scheduleTourButton cardNotClicked"
                  style={{
                    textTransform: 'capitalize',
                    width: '100%',
                    height: '38px',
                    color: '#FFFFFF',
                    backgroundColor: Variables.primaryColor,
                    borderRadius: '8px',
                    padding: '12px',
                    fontWeight: '700',
                    fontFamily: 'Open Sans',
                    fonatSize: '14px !Important',
                  }}
                />
              </Grid>
            </Grid>
          )}

          {props.Tabtype == 'shortlisted' ||
          props.Tabtype == 'contacted' ||
          props.Tabtype == 'seen'
            ? small && (
                <Grid
                  item
                  xs={6}
                  onClick={e =>
                    handleScheduleTourClick(property_id, projectName, e)
                  }>
                  <Grid className={classes.proBtn1}>
                    <HeaderButton
                      text="Call Us"
                      variant="contained"
                      className="scheduleTourButton cardNotClicked"
                      style={{
                        textTransform: 'capitalize',
                        width: '100%',
                        height: '38px',
                        backgroundColor: Variables.primaryColor,
                        borderRadius: '8px',
                        padding: '12px',
                        fontWeight: '700',
                        fontFamily: 'Open Sans',
                        color: '#FFF !important',
                      }}
                    />
                  </Grid>
                </Grid>
              )
            : ''}
        </Grid>
      </div>
    </CommonCardContainer>
  );
}
